import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const SemiDedicatedtPage = () => (
  <div>
      <SEO 
        title="Semi-dedicated Hosting Packages 24–7–365 Client Support" 
        description="You can take advantage of the benefits of a 24/7/365 tech support service with each of our Linux semi-dedicated hosting packages."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="sub-section-wrap py-32">
      <div className="w-full mx-auto bg-white px-5 text-gray-600 mb-2">
          <div className="text-center mx-auto">
              <p className="section-title"><span className="block text-indigo-600 xl:inline">Semi-dedicated</span> Hosting Packages 24–7–365 Client Support<span role="img" aria-label="emoji">✨ </span></p>
              <p className="section-after-title">
              You can take advantage of the benefits of a 24/7/365 tech support service with each of our Linux semi-dedicated hosting packages.
              </p>
          </div>
      </div>
    </div>

    <div className="py-12 bg-gray-50">
        <div className="w-full mx-auto px-5 text-gray-600 mb-10">
          <div className="text-center mx-auto">
              <p className="section-after-title text-indigo-800">
              Assistance Channels
              </p>
          </div>
      </div>
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-wrap -mx-2 overflow-hidden">

            <div className="my-2 px-2 sm:w-2/6 w-full overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="Website Hosting Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/ticket-response-time.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">An Easy–to–Navigate Help Center</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        In the Web Control Panel you’ll find an exhaustive Help section featuring heaps of help articles and commonly 
                        asked questions. They are contributed by our support team of experienced technicians. Just fill in your 
                        question and our intelligent platform will provide you with the most suitable answer.
                        </p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-2/6 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="VPS Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/quality-support-service.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Tutorial Video Clips</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        We have compiled a collection of instructional video tutorials that detailedly show how you can handle a typical 
                        issue connected with your website hosting account. You will find the videos in the Help Center.
                        </p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-2/6 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="Dedicated Hosting Help" 
                            src="https://sheephostingbay.duoservers.com/template2/img/help-center.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">A Sixty–Minute Support Ticket Response Time Guarantee</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        All our semi–dedicated servers are backed up by a 24–7 customer service and feature a 1–hour support ticket response 
                        time guarantee. Our professional technical support engineers will be available to you 24/7 and will sort out any 
                        issue that you might possibly encounter within less than one hour after the tech support ticket submission. In 
                        the majority of cases, a reply will be provided to you within 20 minutes.
                        </p>
                    </div>
                </div>
            </div>

        </div>
      </div>
    </div>
    
    <Subscribe/>
    <Footer/>
  </div>
)

export default SemiDedicatedtPage
